import React from 'react';
import InfoSection from '../InfoSection/InfoSection';
import './style.css';

const Home = () => {
  return (
    <>
      <InfoSection id='home'>Hello</InfoSection>
    </>
  );
};

export default Home;
